import styled from "styled-components";
import { Colors, BP } from "../../commons/Theme";

export const StyledGatsbyImage = styled.div`
  background-color: ${({ backgroundColor }) => (backgroundColor === "grey") ? Colors.grayLight : ""};
`;

export const StyledGatsbyImageDesktop = styled.div`
  display: none;

  @media (${BP.tablet}) {
    display: block;
  }
`;

export const StyledGatsbyImageMobile = styled.div`
  display: block;

  @media (${BP.tablet}) {
    display: none;
  }
`;
